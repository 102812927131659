@font-face {
  font-family: 'SFUIDisplay-Regular';
  src: url('/fonts/SFUIDisplay-Regular/SFUIDisplay-Regular.eot');
  src: url('/fonts/SFUIDisplay-Regular/SFUIDisplay-Regular.woff2') format('woff2'),
    url('/fonts/SFUIDisplay-Regular/SFUIDisplay-Regular.woff') format('woff'),
    url('/fonts/SFUIDisplay-Regular/SFUIDisplay-Regular.ttf') format('truetype'),
    url('/fonts/SFUIDisplay-Regular/SFUIDisplay-Regular.svg#SFUIDisplay-Regular') format('svg'),
    url('/fonts/SFUIDisplay-Regular/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFUIDisplay-Bold';
  src: url('/fonts/SFUIDisplay-Bold/SFUIDisplay-Bold.eot');
  src: url('/fonts/SFUIDisplay-Bold/SFUIDisplay-Bold.woff2') format('woff2'),
    url('/fonts/SFUIDisplay-Bold/SFUIDisplay-Bold.woff') format('woff'),
    url('/fonts/SFUIDisplay-Bold/SFUIDisplay-Bold.ttf') format('truetype'),
    url('/fonts/SFUIDisplay-Bold/SFUIDisplay-Bold.svg#SFUIDisplay-Bold') format('svg'),
    url('/fonts/SFUIDisplay-Bold/SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFUIDisplay-Semibold';
  src: url('/fonts/SFUIDisplay-Semibold/SFUIDisplay-Semibold.eot');
  src: url('/fonts/SFUIDisplay-Semibold/SFUIDisplay-Semibold.woff2') format('woff2'),
    url('/fonts/SFUIDisplay-Semibold/SFUIDisplay-Semibold.woff') format('woff'),
    url('/fonts/SFUIDisplay-Semibold/SFUIDisplay-Semibold.ttf') format('truetype'),
    url('/fonts/SFUIDisplay-Semibold/SFUIDisplay-Semibold.svg#SFUIDisplay-Semibold') format('svg'),
    url('/fonts/SFUIDisplay-Semibold/SFUIDisplay-Semibold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #25272b;
  -webkit-tap-highlight-color: transparent;
  -webkit-highlight: none;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  -webkit-tap-highlight-color: transparent;
  -webkit-highlight: none;
}

input[type=text],
input[type=email],
input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}
