@import '../../styles/colors';

.notification_wrapper {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid $light_grey;
}

.thumbnail {
  position: relative;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 50%;
  background: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 20px;
}

.cancel_button {
  margin-left: auto;
}

.cancel_icon {
  width: 15px;
  padding-left: 5px;
  fill: $grey;
}

.notification_info {
  padding-right: 5px;
}

.date {
  padding-top: 20px;
  font-size: 14px;
  color: $grey;
}

.type_icon_wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat_icon {
  width: 10px;
  fill: $green;
  margin-top: 2px;
}

.notification_icon {
  width: 11px;
  fill: $blue;
}

.transfer_icon {
  width: 13px;
  fill: $blue;
}

.accepted_friend_icon {
  width: 12px;
  fill: $green;
}

.received_friend_icon {
  width: 13px;
  fill: $grey;
}

.text_bold {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
}

.text_right_container {
  text-align: right;
}

.mark_all_btn {
  font-size: 14px;
  color: $grey;

  &:hover {
    color: $dark_grey;
  }
}
