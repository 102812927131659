@import '../../../styles/colors.scss';

.list {
  padding-left: 10px;
}

.item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  &::before {
    display: inline-block;
    content: "";
    width: 5px;
    height: 5px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: $graphite;
  }
}

.item_text {
  flex: 1;
}

.item_link {
  flex: 1;

  &:hover {
    font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  }
}

.show_button {
  color: $blue;
}

.button_arrow {
  transform: rotate(-90deg);
  width: 10px;
  transition: transform 200ms;
}

.button_expanded {
  @extend .button_arrow;

  transform: rotate(90deg);
}
