@import '../../styles/colors.scss';

.history_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.title {
  font-size: 18px;
  word-break: break-word;
}

.date {
  color: $grey;
  font-size: 14px;
  padding-top: 10px;
}

.price {
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;;
  color: $dark_green;
  padding-left: 10px;
}

.price__declined {
  @extend .price;

  color: $grey;
}

.status {
  color: $grey;
  font-size: 12px;
  text-align: right;
}
