@import '../../../styles/colors.scss';

.checkbox_container {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  .checkmark {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid $blue;
    margin-right: 8px;
  }

  .checkmark_icon {
    fill: $blue;
    height: 20px;
    width: 20px;
  }
}
