.user_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 15px;
}

.search_list {
  margin: 15px 0;
}
