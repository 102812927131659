@import '../../../styles/colors';

.progress_bar_wrapper {
  height: 15px;
  border-radius: 10px;
  background-color: $light_grey;
  overflow: hidden;
  flex: 1;
  position: relative;
}

.progress_count {
  position: absolute;
  top: 1px;
  left: 8px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #fff;
}

.progress_count__right {
  position: absolute;
  top: 1px;
  right: 8px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: $blue;
}

.progress_bar {
  height: 100%;

  &::before {
    content: '';
    display: block;
    height: 100%;
    background-color: $blue;
    border-radius: 10px;
    animation: load 1s ease-in-out;
  }
}

@keyframes load {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}
