@import '../../styles/colors.scss';

.checkbox_container {
  margin: 54px 0 30px;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  .checkmark {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid $grey;
    margin-right: 8px;
    cursor: pointer;
    flex-shrink: 0;
  }

  .checkmark_valid {
    @extend .checkmark;
    border: 1px solid $blue;

    .checkmark_icon {
      fill: $blue;
      height: 20px;
      width: 20px;
    }
  }

  .checkmark_invalid {
    @extend .checkmark;
    border: 1px solid $red;
  }

  .checkbox_text {
    font-size: 13px;
  }

  .checkbox_link {
    @extend .checkbox_text;
    text-decoration: underline;
  }
}

.error {
  color: $red;
}

.text {
  padding-bottom: 12px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $graphite;
}

.text_light {
  padding-bottom: 10px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  color: $grey;
  font-size: 14px;
  text-align: justify;
}

.status {
  padding: 10px 30px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $grey;
  font-size: 14px;
  background-color: $beige;
}
