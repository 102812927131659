@import '../../styles/colors';
@import '../../styles/breakpoints';

.table_root {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table_head {
  td {
    padding: 0 4px;
  }
}

.table_title {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.table_subtitle {
  font-size: 18px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
}

.table_body {
  td {
    padding: 20px 4px;
  }
}

.qr_icon {
  width: 25px;
  fill: $green;
  vertical-align: middle;
}

.text_green {
  color: $green;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
}

.text_blue {
  color: $blue;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
}

.text_grey {
  font-size: 14px;
  color: $grey;
  padding-top: 10px;
}

.green_center {
  @extend .text_green;

  text-align: center;
}

.blue_center {
  @extend .text_blue;

  text-align: center;
}

.td_right {
  text-align: right;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  white-space: nowrap;
}

.analytics_row {
  vertical-align: top;
  border-top: 1px solid $light_grey;
}

.total_row {
  border-top: 1px solid $light_grey;
}

.ticket_name {
  font-size: 18px;
}

.date_picker_wrapper {
  position: relative;
  z-index: 5;
  height: 101px;
  background-color: #fff;
  padding: 10px 28px 10px 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 18px 0 rgba(59, 60, 75, 0.1);

  @include breakpoint(xs) {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
  }
}

.scroll_container {
  @import '../../styles/scrollbar';

  position: relative;
  height: calc(100vh - 81px - 101px);
  min-height: calc(100vh - 81px - 101px);
  box-sizing: border-box;
  padding: 30px;
  overflow: auto;
  background-color: #fff;
  z-index: 5;

  @include breakpoint(xs) {
    height: auto;
    padding-bottom: 101px;
  }
}

.period_btn{
  display: block;
  padding: 14px 4px;
  color: $graphite;
  text-align: center;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid $grey;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(59, 60, 75, 0.06);
  cursor: pointer;
  transition: border-color 200ms ease;

  @media (min-width: 1025px) and (max-width: 1400px) {
    font-size: 11px;
  }

  &:hover {
    border: 1px solid $blue;
  }
}

.calendar_icon {
  width: 15px;

  @media (min-width: 1025px) and (max-width: 1400px) {
    width: 10px
  }
}

.radio {
  display: none;

  &:checked + .period_btn {
    @extend .period_btn;
    color: #fff;
    background-color: $blue;
    border-color: $blue;
  }
}

.btns_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.rest_width {
  flex: 1;
}

.cancel_icon {
  width: 12px;
  fill: $grey;
}

.cancel_btn {
  padding: 4px 0 0 8px;
}

.period_item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;

  &:not(:last-child) {
    margin-right: 5px;
  }
}
