@import './colors';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0px 3px 18px 0 rgba(59, 60, 75, 0.04);
  background-color: #ffffff;
  overflow: hidden;
}

.block {
  flex: 1 0 auto;
  box-sizing: border-box;
  background-color: #ffffff;
  cursor: pointer;

  &_active {
    background-color: $blue;

    a {
      color: #ffffff;
    }
  }

  &:first-child>.item {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child>.item {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:nth-child(2) {
    border-left: 1px solid $light_grey;
    border-right: 1px solid $light_grey;
  }

  &:last-child {
    border-right: 1px solid transparent;
  }

  &_double {
    @extend .block;
    width: 50%;
  }
}

.item {
  display: block;
  width: 100%;
  text-align: center;
  padding: 16px 2px;
  box-sizing: border-box;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: $blue;
  text-transform: uppercase;
  transition: background-color 0.3s;

  &:not(.item_active):not(.item_disabled):hover {
    background-color: $light_blue;
  }
}

.item_active {
  @extend .item;
  background-color: $dark_blue;
  color: #ffffff;
}

.item_disabled {
  @extend .item;
  background-color: #fff;
  color: $grey;
}
