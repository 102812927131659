@import '../../../styles/colors.scss';

.button {
  display: block;
  margin: 10px 0;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $blue;
}

.up::after,
.down::after {
  display: inline-block;
  content: '';
  width: 5px;
  height: 5px;
  margin: 0px 10px;
  border-bottom: 1px solid $blue;
  border-right: 1px solid $blue;
  transform: rotate(-135deg);
  transition: all linear 0.4s;
}

.down::after {
  margin: 5px 10px;
  transform: rotate(45deg);
}
