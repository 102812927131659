@import './colors.scss';
@import './breakpoints.scss';

.calendar {
  position: fixed;
  z-index: 20;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 390px;
  padding: 30px 20px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 10px 25px 0 rgba(59, 60, 75, 0.1);
  background-color: white;

  @include breakpoint(xs) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 300px;
  }

  &_container {
    position: absolute;
  }
}

.calendar_on_left {
  @extend .calendar;

  right: unset;
  left: 20px;

  @include breakpoint(xs) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 300px;
  }
}

.tile {
  width: 48px;
  height: 48px;
  color: black;
  border-radius: 5px;

  & time {
    font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
    font-size: 18px;

    @include breakpoint(xs) {
      font-size: 16px;
    }
  }

  &:disabled {
    background-color: white;

    & time {
      color: #e3eaf2;
    }
  }

  &__century {
    @extend .tile;
    width: 100px;

    & time {
      font-size: 14px;

      @include breakpoint(xs) {
        font-size: 12px;
      }
    }
  }
}
