@import './colors.scss';
@import './breakpoints.scss';

.react-calendar {
  max-width: 390px;

  &__tile {
    &:enabled:hover, &enabled:focus {
      background-color: rgba($blue, 0.1);
      border-radius: 0;

      & time {
        color: black;
      }
    }

    &--now time {
      color: $blue;
    }

    &--active:enabled:hover {

      @include breakpoint(xs) {
        background-color: $blue;
        border-radius: 5px;
      }
    }

    &--rangeStart:not(.react-calendar--active), &--rangeEnd:not(.react-calendar--active){
      background-color: $blue;
      border-radius: 5px;

      & time {
        color: white;
      }
    }
  }

  &__navigation {
    &__label  {
      font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
      font-size: 24px;

      @include breakpoint(xs) {
        font-size: 20px;
      }
    }

    & button:disabled {
      background-color: white;
    }

    & button:enabled:hover, & button:enabled:focus {
      background-color: white;
    }
  }

  &__month-view__weekdays__weekday {
    text-transform: none;
    font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: $grey;

    @include breakpoint(xs) {
      font-size: 14px;
    }
  }
}
