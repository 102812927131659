@import '../../styles/colors';
@import '../../styles/breakpoints';

.burger {
  width: 24px;
  animation: spin 200ms ease;
  animation-direction: reverse;
  transition: fill 300ms ease;

  &:hover {
    fill: $dark_grey;
  }
}

.wrapper{
  position: absolute;
  display: flex;
  left: 0;
  top: 80px;
  width: 100%;

  @include breakpoint(xs) {
    top: 79px;
  }
}

.overlay {
  flex: 1;

  @include breakpoint(md_down) {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.menu {
  @import '../../styles/scrollbar.scss';
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27%;
  min-width: 320px;
  box-sizing: border-box;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.07);
  padding: 20px 0 40px;
  height: calc(100vh - 80px);
  overflow: auto;
  background-color: #fff;
  border-top: 1px solid #fff;

  @include breakpoint(xs) {
    width: 80%;
    height: calc(100vh - 79px);
    padding-bottom: 120px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-180deg);
  }
}

.cancel_icon {
  width: 24px;
  box-sizing: border-box;
  padding: 3px;
  animation: spin 300ms ease;

  path {
    fill: $grey;
  }
}

.ticket_icon {
  width: 23px;
  height: 27px;
  fill: $blue;
}

.icon {
  fill: $blue;

  path {
    stroke: $blue;
  }
}

.careers_icon {
  @extend .icon;
  width: 33px;
  height: 34px;

  path {
    stroke-width: 2;
  }
}

.feedback_icon {
  @extend .icon;
  width: 28px;
  height: 25px;
}

.contact_icon {
  @extend .icon;
  width: 30px;
  height: 30px;
}

.apparel_icon {
  @extend .icon;
  width: 37px;
  height: 37px;
  stroke-width: 6;
  stroke: #0077ee;
}

.qr_scan_icon {
  width: 29px;
  fill: $blue;
}

.friends_icon {
  @extend .icon;
  width: 30px;

  path {
    stroke-width: 2;
  }
}

.link {
  font-size: 24px;
  display: flex;
  align-items: center;
  padding: 15px;
  transition: all 300ms ease;

  &:hover {
    background-color: $blue;
    color: #fff;

    svg {
      fill: #fff;
    }

    .careers_icon path,
    .feedback_icon path,
    .friends_icon path {
      stroke: #fff;
    }
  }
}

.smartphone {
  @extend .icon;
  width: 21px;
}

.icon_box {
  box-sizing: border-box;
  min-width: 37px;
  text-align: center;
  margin-right: 20px;
}

.separator {
  border-bottom: 1px solid $light_grey;
  margin: 15px;
}

.sub_menu {
  color: $grey;
  padding: 0 15px;
}

.terms_link {
  font-size: 12px;
  color: $grey;
  padding: 0 5px;
  transition: color 200ms ease;

  &:hover {
    color: $dark_grey;
  }
}
