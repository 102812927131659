@import '../../styles/breakpoints.scss';
@import '../../styles/colors.scss';

.menu_wrapper {
  position: relative;
}

.drop_down {
  box-sizing: border-box;
  position: absolute;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0px 8px 18px 0 rgba(59, 60, 75, 0.15);
  width: 230px;
  top: 50px;
  left: 50%;
  transform: translateX(-46%);
  border-radius: 0 0 10px 10px;
  padding: 30px;

  @include breakpoint(xs) {
    border-radius: 0;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    transform: none;
  }
}

.dropdown_item {
  text-align: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.overlay {
  @include breakpoint(xs) {
    content: '';
    position: fixed;
    display: block;
    width: 100%;
    z-index: 3;
    top: 80px;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5)
  }
}

.create_btn {
  font-size: 17px;
  color: $graphite;
  cursor: pointer;
  margin-left: 50px;

  @include breakpoint(xs) {
    margin-left: 20px;
  }
}

.plus_icon {
  @include breakpoint(xs) {
    color: #000;
    font-size: 30px;
    font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  }
}
