@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.photos_container {
  position: relative;
  text-align: center;


  img.slide {
    max-width: 100%;
    max-height: 90vh;
    width: auto;
    display: inline-block;
  }
}

.button_left {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -30px;
}

.arrow_left {
  fill: #fff;
}

.button_right {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -30px;
  fill: #fff;
}

.arrow_right {
  fill: #fff;
  transform: rotate(180deg);
}

.cancel_icon {
  width: 18px;
  box-sizing: border-box;

  path {
    fill: #fff;
    stroke: $grey;
  }
}

.pager_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle_button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 8px;
}

.active_circle {
  @extend .circle_button;

  background-color: $grey;
}
