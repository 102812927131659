@import '../../styles/colors.scss';

.search_form {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 2px solid $blue;
  margin: 35px 0;
}

.search_icon {
  width: 20px;
  padding: 5px;
  fill: $graphite;
}

.search_input {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 10px 0;
  border: none;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
}
