@import '../../../styles/colors.scss';

.container {
  margin-bottom: 20px;
  border-bottom: 1px solid $light_grey;
}

.title {
  flex: 1;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 30px;
  color: $graphite;
  word-break: break-word;

  &_withArrow {
    composes: title;
    position: relative;
    font-size: 18px;
    cursor: pointer;
  }
}

.arrow {
  width: 8px;
  margin: 5px;
  cursor: pointer;
  fill: $graphite;
}

.arrow_wrapper {
  width: 16px;
  height: 16px;
  margin: 0 10px 2px 0;
}

.text_dark {
  margin-bottom: 24px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: $dark_grey;

  & div {
    padding-left: 0;
  }
}

.text_light {
  margin-bottom: 10px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: $grey;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  background-color: #fff;
}

.text_small {
  padding: 10px 0;
  font-size: 12px;
  text-align: center;
  color: $grey;
}
