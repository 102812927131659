@import './breakpoints';
@import './sizes';

.grid {
  display: flex;
  width: 100%;
  height: calc(100vh - #{$header_height});
  justify-content: space-between;

  @include breakpoint(xs) {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
}

.side_bar {
  @import './scrollbar.scss';

  position: relative;
  width: 27%;
  min-width: 320px;
  box-sizing: border-box;
  padding: 30px;
  height: calc(100vh - #{$header_height});
  overflow: auto;
  background-color: #fff;

  @include breakpoint(md_down) {
    width: 45%;
  }

  @include breakpoint(xs) {
    width: 100%;
    flex-shrink: 0;
    height: auto;
    padding: 30px 10px;
  }
}

.side_bar_wide {
  @extend .side_bar;
  width: 35%;

  @include breakpoint(md_down) {
    width: 45%;
  }

  @include breakpoint(xs) {
    width: 100%;
  }
}

.side_bar_with_inactive_banner {
  @extend .side_bar;
  padding: 0;
}

body {
  @include breakpoint(xs) {
    padding-top: 80px;
  }
}

.wrapper_fixed_bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27%;
  min-width: 320px;
  min-height: calc(100% - 80px);
  background-color: #fff;
  transition: width 500ms;

  @include breakpoint(md_down) {
    width: 45%;
  }

  @include breakpoint(xs) {
    width: 100%;
  }
}

.mobile_on_top {
  position: relative;

  @include breakpoint(xs) {
    position: absolute;
    z-index: 4;
    width: 100%;
    min-height: calc(100vh - 81px);
  }
}

.scroll_container {
  @import './scrollbar.scss';
  position: relative;
  height: calc(100vh - #{$header_height} - 130px);
  min-height: calc(100vh - #{$header_height} - 130px);
  box-sizing: border-box;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.07);
  padding: 30px;
  overflow: auto;
  background-color: #fff;
  z-index: 5;

  @include breakpoint(xs) {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    padding: 30px 10px;
  }
}

.scroll_container_wo_buttons {
  @extend .scroll_container;
  height: calc(100vh - #{$header_height});
  min-height: calc(100vh - #{$header_height});

  @include breakpoint(xs) {
    height: auto;
  }
}

.scroll_container_big {
  @extend .scroll_container;
  height: calc(100vh - #{$header_height} - #{$bottom_bar_height});
  min-height: calc(100vh - #{$header_height} - #{$bottom_bar_height});

  @include breakpoint(xs) {
    height: auto;
  }
}

.scroll_container_pass {
  @extend .scroll_container_big;
  height: calc(100vh - #{$header_height} - 60px);
}

.scroll_container_wo_buttons_with_banner {
  @extend .scroll_container_wo_buttons;
  height: calc(100vh - #{$header_height} - #{$banner_height});
  min-height: calc(100vh - #{$header_height} - #{$banner_height});

  @include breakpoint(xs) {
    height: auto;
  }
}

.scroll_container_wo_buttons_with_inactive_banner {
  @extend .scroll_container_wo_buttons;
  height: calc(100vh - #{$header_height} - #{$inactive_banner_height});
  min-height: calc(100vh - #{$header_height} - #{$inactive_banner_height});

  @include breakpoint(small_screen) {
    height: calc(100vh - #{$header_height} - #{$inactive_banner_height_small_screen});
    min-height: calc(100vh - #{$header_height} - #{$inactive_banner_height_small_screen});
  }
}

.scroll_container_big_with_banner {
  @extend .scroll_container_big;
  height: calc(100vh - #{$header_height} - #{$bottom_bar_height} - #{$banner_height});
  min-height: calc(100vh - #{$header_height} - #{$bottom_bar_height} - #{$banner_height});

  @include breakpoint(xs) {
    height: auto;
  }
}

.scroll_container_big_with_inactive_banner {
  @extend .scroll_container_big;
  height: calc(100vh - #{$header_height} - #{$bottom_bar_height} - #{$inactive_banner_height});
  min-height: calc(100vh - #{$header_height} - #{$bottom_bar_height} - #{$inactive_banner_height});

  @include breakpoint(small_screen) {
    height: calc(100vh - #{$header_height} - #{$bottom_bar_height} - #{$inactive_banner_height_small_screen});
    min-height: calc(100vh - #{$header_height} - #{$bottom_bar_height} - #{$inactive_banner_height_small_screen});
  }

  @include breakpoint(xs) {
    height: auto;
  }
}
