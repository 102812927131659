@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints.scss';

.card {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 0px 3px 24px 0 rgba(59, 60, 75, 0.1);
  background-color: #fff;
  cursor: pointer;
  transition: all 200ms;
  padding: 20px;

  @include breakpoint(md) {
    &:hover {
      box-shadow: 0px 3px 24px 0 rgba(59, 60, 75, 0.25);
      transform: scale(1.07);
    }
  }
}

.card_header {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ebf0fb;
}

.title,
.location,
.address,
.style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-size: 18px;
  line-height: 28px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $graphite;
}

.location_box {
  max-width: calc(100% - 24px);
}

.location {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $graphite;
}

.recurrent_label {
  font-size: 12px;
  color: #fff;
  background-color: $grey;
  border-radius: 5px;
  padding: 2px 4px;
}

.date {
  font-size: 12px;
  line-height: 1.5;
}

.address {
  font-size: 12px;
  color: $grey;
}

.time {
  font-size: 14px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $blue;
}

.info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;
}

.no_wrap {
  flex-wrap: nowrap;
}

.grey_text {
  font-size: 12px;
  color: $grey;
}

.day {
  color: $grey;
  font-size: 11px;
  line-height: 16px;

  &:not(:last-child)::after {
    content: '/';
    display: inline;
  }

  &_selected {
    @extend .day;
    color: $blue;
  }
}

.btn_box {
  float: right;
  padding-left: 5px;

  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

.recurrent_column {
  word-break: break-word;
}

.info_icon {
  width: 16px;
  fill: $grey;
  margin-right: 8px;
  flex-shrink: 0;
}
