@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }

  @else if $class == md_down {
    @media (max-width: 1024px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == small_screen {
    @media
      (min-width: 1025px) and (max-width: 1440px),
      (max-width: 400px),
      (min-width: 768px) and (max-width: 830px) { @content; }
  }

  @else if $class == iphone_5 {
    @media only screen
      and (min-width : 320px)
      and (max-width : 340px)
      and (orientation: portrait) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}
