@import '../../../styles/breakpoints';

.container {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.4);
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 50%;
  padding: 50px 50px 40px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 7px 32px 0 rgba(59,60,75,.08);

  @include breakpoint(md_down) {
    left: 25%;
    margin-left: 20px;
  }

  @include breakpoint(xs) {
    width: 95vw;
    left: 50%;
    margin: 0;
    padding: 20px 20px 15px;
  }
}

.photo {
  padding-bottom: 30px;
  text-align: center;
  max-height: 50vh;
}

.buttons {
  @include breakpoint(xs) {
    margin-top: 15px;
  }
}
