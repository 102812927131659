@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';

.text_bold {
  padding-bottom: 20px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $graphite;
}

.text {
  padding-bottom: 18px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: $graphite;
}

.list_item {
  @extend .text;

  &::before {
    content: "\2022";
    color: $blue;
    font-weight: bold;
    display: inline-block;
    width: 1em;
  }
}

.close_btn_box {
  text-align: right;
  padding-bottom: 7px;
}
