@import '../../styles/colors.scss';
@import '../../styles/breakpoints';

.container {
  @import '../../styles/scrollbar.scss';

  width: 100%;
  position: relative;
  height: calc(100vh - 81px - 70px);
  min-height: calc(100vh - 81px - 70px);
  box-sizing: border-box;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.07);
  padding: 30px;
  overflow: auto;
  background-color: #fff;
  z-index: 5;

  @include breakpoint(xs) {
    position: absolute;
    z-index: 4;
    min-height: calc(100vh - 80px);
  }
}

.container_longer {
  @extend .container;
  height: calc(100vh - 81px);
  min-height: calc(100vh - 81px);
}

.checkin_wrapper {
  @include breakpoint(xs) {
    position: absolute;
  }
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.avatar {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  background: no-repeat;
  background-size: cover;
  position: relative;
  margin-right: 20px;
  flex-shrink: 0;
}

.name {
  flex: 1;
  font-size: 18px;
  word-break: break-word;
}

.qr {
  width: 35px;
  min-width: 35px;
  height: 35px;
  margin-left: 10px;
  fill: $grey;

  &_done {
    @extend .qr;
    fill: $dark_green;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 5px;
  padding: 10px;

  &_white {
    @extend .close;

    & path {
      fill: white;
    }
  }
}

.scanner {
  z-index: 20;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: rgba(0,0,0,.4);
  box-shadow: 0px 7px 32px 0 rgba(59, 60, 75, 0.08);
  border-radius: 15px;

  @include breakpoint(xs) {
    width: 90%;
    height: 90vw;
  }

  * {
    border-radius: 15px;
  }

  div {
    /* stylelint-disable */
    box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 5px inset !important;
    /* stylelint-enable */
  }
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  overflow-y: auto;
}

.scan_btn_container {
  min-width: 320px;
  background-color: #fff;
  padding: 0 30px;
  box-sizing: border-box;
  z-index: 5; // for safari bugfix
}

.grey_text {
  color: $grey;
}

.flex_row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
