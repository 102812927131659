@import './colors.scss';
@import './breakpoints.scss';

.container {
  position: relative;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  &_error {
    composes: container;

    & .input {
      border: 1px solid $red;
      color: $red;

      &+.icon svg{
        fill: $red;
      }
    }

    & .label {
      color: $red;
    }

    & .dash {
      border: 1px solid $red;
    }
  }
}

.date_box {
  z-index: 0;
  position: relative;
  max-width: 170px;
  width: 45%;
  height: 65px;
}

.input{
  position: absolute;
  box-sizing: border-box;
  top: 20px;
  left: 0;
  max-width: 170px;
  width: 100%;
  height: 45px;
  padding-left: 30%;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $graphite;
  outline: none;
  border: 1px solid $grey;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  background-color: transparent;

  &_active {
    composes: input;
    border: 1px solid $blue;

    &+.icon svg{
      fill: $blue;
    }
  }
}

.label {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: $grey;

  &__active {
    composes: label;
    color: $blue;
  }
}

.icon {
  position: absolute;
  top: 35px;
  left: 10%;

  & svg {
    fill: $grey;
    cursor: pointer;
  }
}

.dash {
  width: 4%;
  height: 0;
  margin-top: 15px;
  border: 1px solid $grey;

  &_active {
    composes: dash;
    border: 1px solid $blue;
  }
}

.calendar {
  position: absolute;
  z-index: 20;
  right: 20px;
  width: 390px;
  padding: 30px 20px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 10px 25px 0 rgba(59, 60, 75, 0.1);
  background-color: white;

  @include breakpoint(xs) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 300px;
  }
}
