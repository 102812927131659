@import '../../../styles/colors.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 5px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: $graphite;
}

.wrapper {
  box-sizing: border-box;
  max-width: 45px;
  width: 45px;
  margin-right: 5px;
  height: 45px;
}

.label{
  display: block;
  padding: 14px 0;
  color: $graphite;
  text-align: center;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid $grey;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(59, 60, 75, 0.06);
  cursor: pointer;
  transition: border-color 200ms ease;

  &:hover {
    border: 1px solid $blue;
  }
}

.item {
  display: none;

  &:checked+.label {
    @extend .label;
    color: #fff;
    background-color: $blue;
    border-color: $blue;
  }
}
