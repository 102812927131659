@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';

.controllers {
  &_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &_without_filter {
      @extend .controllers_container;
      width: 100%;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
  }

  &_view {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

    svg {
      cursor: pointer;
    }
  }

  &_item {
    margin: 16px 0 16px 16px;

    &:hover svg {
      fill: $light_grey_hover;
    }
  }
}

.light_grey {
  fill: $light_grey;
}

.dark_grey {
  fill: $dark_grey;
}

.star_button {
  position: absolute;
  top: 10px;
  left: 10px;
}

.star {
  cursor: pointer;

  fill: $light_grey;
  width: 15px;
}

.star_active {
  cursor: pointer;

  fill: $dark_grey;
  width: 15px;
}

.narrowing {
  transform: scale(0.95);
}

.item {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  display: block;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 0px 3px 24px 0 rgba(59, 60, 75, 0.15);
  background-color: white;
  cursor: pointer;
  transition: all 200ms;
  overflow: hidden;

  @include breakpoint(md) {
    &:hover {
      box-shadow: 0px 3px 24px 0 rgba(59, 60, 75, 0.25);
      transform: scale(1.07);
    }
  }

  &_star {
    width: 23px;

    path{
      fill: #FFF;
      stroke: $grey;
      stroke-opacity: 0.25;
    }
  }
}

.list {
  @extend .item;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 134px;

  .image {
    min-width: 100px;
    width: 35%;
    overflow: hidden;
    border-bottom-left-radius: 15px;
  }
}

.place,
.place_venue {
  &::before {
    display: block;
    content: '';
    width: 70%;
    margin: 1px 0;
    border-bottom: 1px solid #ebf0fb;
  }
}

.thumbnails {
  @extend .item;

  .image {
    height: 220px;
    width: 100%;
    border-top-right-radius: 15px;
  }

  .info {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px 20px 15px;
  }

  .title {
    order: 1;
    width: 100%;
    padding: 10px 0;
    font-size: 22px;
    height: auto;
    white-space: nowrap;
    line-height: 19px;

    &::first-letter {
      text-transform: uppercase;
    }

    &::after {
      display: none;
    }
  }

  .title_venue {
    order: 0;
    padding: 5px 0 0;
    font-size: 22px;

    &::after {
      display: block;
      content: '';
      width: 70%;
      margin-top: 10px;
      border-bottom: 1px solid #ebf0fb;
    }
  }

  .date {
    order: 2;
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 14px;
    border-right: 1px solid #ebf0fb;
    font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  }

  .day {
    display: block;
    text-align: center;
    font-size: 24px;
    color: #2e76ea;
  }

  .month {
    font-size: 14px;
    color: #2e76ea;
    font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  }

  .place {
    order: 3;
    width: 80%;
    padding: 4px 0 0 10px;
    box-sizing: border-box;

    &::before {
      display: none;
    }
  }

  .place_venue {
    order: 3;
    box-sizing: border-box;
    padding: 5px 0 0 0;
    max-width: 100%;

    &::before {
      display: none;
    }
  }

  .location {
    font-size: 16px;
  }

  .address {
    font-size: 12px;
  }

  .info_row {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .info_icon {
    fill: $grey;
    width: 16px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .weekday {
    font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;

    &:not(:last-child)::after {
      content: ' / ';
      display: inline;
    }
  }

  .calc_width {
    max-width: calc(100% - 24px);
  }
}

.image {
  overflow: hidden;
  border-top-left-radius: 15px;
  background: no-repeat;
  background-size: cover;
  background-position: center;
}

.info {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;
  padding: 12px 20px;
  overflow: hidden;
}

.day {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $blue;
  font-size: 12px;
}

.title,
.title_venue {
  max-height: 40px;
  font-size: 17px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  color: $graphite;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  flex-shrink: 0;

  span {
    font-size: 17px;
    font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
    color: $graphite;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.location {
  margin-bottom: 5px;
  font-size: 14px;
  color: $graphite;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
}

.address {
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: $grey;
}

.location,
.address,
.style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style {
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: $grey;
  margin-bottom: 2px;

  &::after {
    display: inline-block;
    content: '';
    margin: 2px 5px;
    border-radius: 50%;
    border: 2px solid $grey;
  }

  &:last-child::after {
    display: none;
  }
}

.style_container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.wrapper {
  @include breakpoint(sm) {
    overflow-y: scroll;
  }
}

.weekday {
  font-size: 14px;

  &:not(:last-child)::after {
    content: '/ ';
    display: inline;
  }
}

.weekday_active {
  @extend .weekday;
  color: $blue;
}

.hosting_status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px 10px;
  background-color: $grey;
  color: #fff;
  font-size: 11px;
  border-bottom-left-radius: 15px;
}

.status_red {
  @extend .hosting_status;

  background-color: $red;
}
