@import '../../../styles/colors';


.link_wrapper {
  display: flex;
  align-items: center;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 20px;
  flex-shrink: 0;
  background-color: $light_grey;
}

.secondary_name {
  padding-top: 3px;
  color: $grey;
  font-size: 12px;
}

.name_box {
  padding-right: 5px;
}

.status {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.user_box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.dots {
  width: 5px;
  fill: $blue;
}

.submenu {
  position: absolute;
  top: 0;
  right: 0;
}

.dots_button {
  line-height: 0;
  margin-left: auto;
}
