@import './colors.scss';
@import './breakpoints.scss';

.title {
  padding-bottom: 15px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 30px;
  color: $graphite;
}

.subtitle {
  @extend .title;
  font-size: 24px;

  &::before {
    content: '';
    display: block;
    border-bottom: 1px solid $light_grey;
    margin-bottom: 15px;
  }
}

.error {
  margin-bottom: 15px;
  text-align: center;
  color: $red;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.price_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.venue {
  color: $grey;
  padding: 10px 0 10px 20px;

  &_title {
    padding-left: 20px;
  }

  &_container {
    position: relative;
  }
}

.cancel {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
}

.input_right_btn {
  position: absolute;
  top: 8px;
  right: 3px;
}

.pin_icon {
  width: 15px;
  fill: $blue;
}

.price_message {
  color: $dark_grey;
  font-size: 12px;
  padding: 0 5px 5px;
}

.checkmark {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid $grey;
  margin-right: 8px;
  cursor: pointer;
  flex-shrink: 0;
}

.checkmark_valid {
  @extend .checkmark;
  border: 1px solid $blue;
}

.checkmark_icon {
  fill: $blue;
  height: 20px;
  width: 20px;
}

.droppable {
  /* stylelint-disable */
  overflow-anchor: auto !important;
  /* stylelint-enable */
}
