@import '../../styles/colors';

.ticket_wrapper {
  border-radius: 15px;
  box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 20px;
}

.ticket_wrapper_consumed {
  @extend .ticket_wrapper;

  filter: grayscale(100%);
  opacity: 0.65;
}

.img_box {
  position: relative;
  height: 180px;
  background: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.qr_white {
  width: 52px;
  height: 52px;
  margin: 20px;
}

.info {
  position: relative;
  padding: 30px;
}

.one_line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  @extend .one_line;

  display: block;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
}

.title_pass {
  @extend .title;
  margin-bottom: 10px;
}

.info_row {
  display: flex;
}

.location {
  @extend .one_line;

  color: $grey;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
}

.address {
  @extend .one_line;

  color: $grey;
  font-size: 12px;
  line-height: 21px;
}

.date {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid $light_grey;
  text-align: center;
}

.date_number {
  color: $blue;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 21px;
}

.date_day {
  @extend .one_line;

  color: $blue;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
}

.location_box {
  overflow: hidden;
}

.past_separator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  color: $grey;
  text-transform: uppercase;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;


  &::before,
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid $grey;
    flex: 1
  }

  &::before {
    margin-right: 15px;
  }

  &::after {
    margin-left: 15px;
  }
}

.sub_title {
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
}

.display_id {
  position: absolute;
  top: 10px;
  right: 20px;
  color: $grey;
  font-size: 12px;
}
