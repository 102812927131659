@import './colors.scss';

.title {
  position: relative;
  font-size: 18px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $graphite;
  cursor: pointer;
  word-break: break-word;
  flex: 1;

  &_withArrowUp,
  &_withArrowDown {
    @extend .title,
  }

  &_withArrowUp::after,
  &_withArrowDown::after {
    display: block;
    content: '';
    position: absolute;
    top: 10px;
    right: 5px;
    width: 5px;
    height: 5px;
    margin: 0px 10px;
    border-bottom: 1px solid $graphite;
    border-right: 1px solid $graphite;
    transform: rotate(-135deg);
  }

  &_withArrowDown::after {
    top: 0;
    margin: 5px 10px;
    transform: rotate(45deg);
  }
}

.container {
  margin-bottom: 15px;
  border-bottom: 1px solid $light_grey;

  &_closed {
    @extend .container;
    padding-bottom: 0;
  }
}

.error {
  margin-bottom: 15px;
  color: $red;
}

.text_small {
  padding: 10px 0;
  font-size: 12px;
  text-align: center;
  color: $grey;
}

.checkbox_row {
  display: flex;
  justify-content: space-between;
}

.checkbox_item {
  width: 45%;
  margin-bottom: 15px;
}

.checkbox_container {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;

    &:checked ~ .checkmark {
      background-color: $blue;
    }
  }

  .checkmark {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid $blue;
    margin-right: 8px;
  }

  &:hover {
    .checkmark {
      background-color: $dark_blue;
    }
  }

  .title {
    padding: 0;
  }
}

.price_message {
  color: $dark_grey;
  font-size: 12px;
  padding: 0 5px 5px;
}

.title_row {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  background-color: #fff;
}

.burger {
  width: 15px;
  fill: $grey;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    fill: $dark_grey;
  }
}
