@import './colors';

.method_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.method_item_text {
  display: flex;
  padding: 10px 0;
}

.dropdown_wrapper {
  position: relative;
}

.dropdown {
  position: absolute;
  right: 0;
  z-index: 2;
  min-width: 100px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 4px 5px 16px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.drop_btn {
  display: block;
  width: 100%;
  white-space: nowrap;
  padding: 5px 20px;
  text-align: left;
  font-size: 14px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  line-height: 32px;
  background-color: white;
  cursor: pointer;

  &:hover{
    background-color: $blue;
    color: white;
  }
}

.funding,
.last4 {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
}

.funding {
  margin: 0 auto 0 15px;
}

.last4 {
  margin: 0 15px 0 auto;
}

.card_logo {
  width: 50px;
  height: 30px;
  background: url('../images/cards_sprite.png') no-repeat;
  background-size: 260px;
  flex-shrink: 0;
  border-radius: 5px;
  overflow: hidden;
}

.no_logo {
  background-position: right top;
}

.visa {
  background-position: -53px 0;
}

.master_card {
  background-position: 0 0;
}

.american_express {
  background-position: -105px 0;
}

.discover {
  background-position: -157px 0;
}

.add_method_wrapper {
  padding: 10px 0;
}

.small_text {
  font-size: 12px;
  color: $grey;
}

.radio {
  margin-right: 10px;
}

.expired {
  padding-bottom: 2px;
  font-size: 10px;
  text-align: center;
  color: white;
  background-color: $red;
}
