@import '../../styles/colors.scss';
@import '../../styles/breakpoints';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  min-width: 310px;
  height: auto;
  min-height: calc(100vh - 81px);
  overflow: visible;
  box-sizing: border-box;

  @include breakpoint(xs) {
    position: fixed;
    top: 79px;
    padding: 30px;
    height: calc(100vh - 79px);
    overflow: auto;
  }
}

.icon {
  fill: $dark_grey;
}

.icon_active {
  fill: $blue;
}

.icon_container {
  display: flex;
  align-items: flex-start;

  &:hover {
    .title {
      color: $light_grey_hover;
    }

    .icon,
    .icon_active {
      fill: $light_grey_hover;
    }
  }
}


.title {
  padding-left: 10px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: $graphite;

  &_active {
    @extend .title;
    color: $blue;
  }
}

.marker_icon {
  position: absolute;
  width: 16px;
  height: 20px;
  fill: $blue;
}

.close_round_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 0 10px 10px;
}

.slider {
  position: relative;

  &_container {
    width: 100%;
    margin: 50px 0 20px 0;
  }

  &_range {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 10px;

    &_value {
      font-family: 'SFUIDisplay-Regular';
      font-size: 16px;
      color: $graphite;
    }
  }

  &_limiter {
    position: relative;
    width: calc(100% - 24px);
  }
}

.select {
  width: 100%;
  min-width: 130px;

  &_title {
    padding: 0 0 5px 5px;
    font-family: 'SFUIDisplay-Regular';
    font-size: 18px;
    color: $graphite;
  }
}

.selected_container {
  order: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.selected_item {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px 8px;
  margin: 2px 2px 2px 0;
  background-color: $blue;
  color: #fff;
  font-size: 14px;
}

.cancel_icon {
  width: 8px;
  fill: #fff;

  &:hover {
    fill: #000;
  }
}

.cancel_button {
  padding-left: 7px;
}
