@import '../../styles/colors.scss';
@import '../../styles/breakpoints';

.container {
  position: relative;
}

.search_wrapper {
  position: relative;
}

.search_form {
  position: relative;
  margin-bottom: 25px;
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 0 80px 10px 10px;
  border: none;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  border-bottom: 2px solid $grey;
  transition: border-color 300ms ease;

  &:focus {
    border-bottom: 2px solid $blue;
  }
}

.search {
  position: absolute;
  right: 10px;
  padding: 4px 10px 10px 10px;
  cursor: pointer;
  fill: $graphite;

  &:hover {
    fill: $blue;
  }
}

.text {
  &_grey {
    font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: $grey;
  }

  &_black {
    font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
    font-size: 24px;
    color: $graphite;
  }
}
