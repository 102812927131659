@import '../../styles/breakpoints';
@import '../../styles/colors';

.overlay {
  @import '../../styles/scrollbar';
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  overflow-y: auto;
}

.content {
  position: relative;
  width: 550px;
  padding-bottom: 50px;
  top: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 32px 0 rgba(59, 60, 75, 0.08);

  @include breakpoint(xs) {
    width: 90%;
    top: 40px;
  }

  @include breakpoint(iphone_5) {
    width: 100%;
    border-radius: 0;
  }
}

.content_mobile_full_width {
  @extend .content;

  @include breakpoint(xs) {
    width: 100%;
    border-radius: 0;
  }
}

.content_box {
  width: 410px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 40px;
  box-sizing: border-box;

  @include breakpoint(xs) {
    width: 300px;
    padding: 0;
  }
}

.content_box_left {
  @extend .content_box;

  text-align: left;
}

.message_box{
  @extend .content_box;

  width: 100%;
  padding: 0 0 20px;
  box-sizing: border-box;
}

.close_button_box {
  text-align: center;
  padding: 50px 0 0;
}

.title {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
}

.top_row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user_ticket_content {
  width: 450px;
  padding-bottom: 50px;
  margin: 70px auto;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 7px 32px 0 rgba(59, 60, 75, 0.08);
  padding: 20px 20px 50px;
  box-sizing: border-box;

  @include breakpoint(xs) {
    width: 95%;
    padding: 20px 5px 50px;
  }

  @include breakpoint(iphone_5) {
    width: 100%;
    border-radius: 0;
  }
}

.slider_overlay {
  @extend .overlay;
  background-color: rgba(0, 0, 0, 1);
}

.content_slider {
  position: relative;
  width: 80%;
  margin: 70px auto 0;

  @include breakpoint(xs) {
    width: 100%;
  }
}

.close_button_right {
  position: absolute;
  top: 18px;
  right: 18px;
}

.close_icon {
  width: 18px;
  fill: $grey;
  transition: fill 200ms ease;

  &:hover {
    fill: $dark_grey;
  }
}

.close_button_fixed {
  position: fixed;
  top: 10px;
  right: 15px;
}

.avatar {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  background: no-repeat;
  background-size: cover;
  position: relative;
  margin-right: 20px;
  flex-shrink: 0;
}

.user {
  display: flex;
}

.text {
  padding-top: 10px;
}

.title_with_text {
  @extend .title;
  padding-bottom: 20px;
}

.text_grey {
  padding-bottom: 40px;
  font-size: 16px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  color: $graphite;
}

.light_grey_text {
  font-size: 15px;
  color: $grey;
  padding-bottom: 10px;
}

.light_grey_text_small {
  @extend .light_grey_text;

  font-size: 12px;
}

.wrapper {
  padding-top: 30px;
}

.big_price {
  font-size: 32px;
}

.avatar_editor_box {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.checkbox_wrapper {
  padding-bottom: 40px;
}

.error {
  color: $red;
  font-size: 14px;
  padding-bottom: 10px;
}

.label {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  text-transform: capitalize;
}

.subtitle {
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
}
