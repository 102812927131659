@import '../../styles/colors.scss';

.error_container {
  position: fixed;
  bottom: 0;
  z-index: 30;
  background-color: $red;
  border-radius: 5px;
  padding: 24px 40px 24px 24px;
  text-align: center;
  color: #fff;
}

.cancel_button {
  position: absolute;
  right: 11px;
  top: 8px;
}

.cancel_icon {
  width: 12px;
  fill: #fff;
  transition: fill 200ms ease;

  &:hover {
    fill: $dark_grey;
  }
}
