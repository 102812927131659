@import '../../../styles/colors.scss';

.dots {
  width: 5px;
}

.dots_active {
  fill: $blue;
}

.dots_inactive {
  color: $grey;
  pointer-events: none;
  fill: $grey;
}

.submenu {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 4px 5px 16px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  &_item {
    font-size: 14px;
    font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
    line-height: 32px;
    background-color: white;
    cursor: pointer;

    &:hover{
      background-color: $blue;
      color: white;
    }
  }
}

.submenu_bottom {
  @extend .submenu;
  top: auto;
  bottom: 14px;
  right: 10px;
}

.button {
  width: 100%;
  padding: 5px 20px;
  text-align: left;
}
