@import './colors';

&::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

&::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

&::-webkit-scrollbar-thumb {
  background: $grey;
  border: 0px none #ffffff;
  border-radius: 8px;

  &:hover {
    background: $dark_grey;
  }
}

&::-webkit-scrollbar-track {
  background: $light_grey;
  border: 0px none #000000;
  border-radius: 50px;
}

&::-webkit-scrollbar-corner {
  background: transparent;
}
