@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';

.title {
  margin-bottom: 30px;
  font-size: 30px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $graphite;
  text-transform: capitalize;
}

.ticket {
  margin-bottom: 25px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.07);
}

.ticket_disabled {
  @extend .ticket;
  opacity: 0.5;

  & .count_button {
    pointer-events: none;
  }
}

.wrapper_top, .wrapper_bottom {
  position: relative;
  box-sizing: border-box;
  min-height: 110px;
  padding: 30px 30px 20px;
}

.wrapper_top {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  * {
    color: white;
  }
}

.description {
  margin-bottom: 5px;
  font-size: 18px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  color: $graphite;
  word-break: break-word;

  span {
    font-size: 18px;
    font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
    color: $graphite;
  }
}

.name, .price {
  font-size: 30px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
}

.name {
  padding-right: 30px;
  word-break: break-word;
}

.date {
  font-size: 18px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
}

.side_info {
  margin-left: auto;
  text-align: right;
}

.quantity {
  font-size: 16px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
}

.grey {
  font-size: 16px;
  color: $grey;

  &::after {
    display: inline-block;
    content: '';
    margin: 4px 5px;
    border-radius: 50%;
    border: 2px solid $grey;
  }

  &:last-child::after {
    display: none;
  }
}

.count_button {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  border: 1px solid $grey;
  text-align: center;
  line-height: 10px;
  transition: background-color 300ms ease;

  &:hover {
    background-color: $dark_blue;
    border-color: transparent;

    .count_icon {
      fill: #fff;
    }
  }

  &.minus_count:hover {
    background-color: $dark_red;
  }
}

.count_icon {
  width: 10px;
  fill: #000;
}

.total_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include breakpoint(sm) {
    padding-top: 10px;
  }
}

.total_container_up {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid $light_grey;

  .sum {
    color: $graphite;
  }
}

.total {
  font-size: 24px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
}

.sum {
  @extend .total;
  color: $blue;
}

.sub_title {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
}

.message {
  padding-bottom: 10px;
}

.ticket_count {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  min-width: 55px;
  padding: 0 16px;
  font-size: 18px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  color: $graphite;
}

.count_container {
  margin-top: 20px;
}


.spacer_xs {
  @include breakpoint(xs) {
    height: 89px;
  }
}

.checkout_btn_box{
  @include breakpoint(sm) {
    padding: 10px 0 20px;
  }
}
