@import '../styles/_breakpoints.scss';

.content_box {
  width: 410px;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
  padding: 20px;

  @include breakpoint(xs) {
    width: 300px;
    padding: 0;
  }
}

.wrapper {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 7px 32px 0 rgba(59, 60, 75, 0.08);
  padding-bottom: 50px;
  width: 600px;

  @include breakpoint(xs) {
    width: 100%;
  }
}

.backdrop {
  @import '../styles/scrollbar.scss';
  padding: 70px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  @include breakpoint(xs) {
    padding: 0;
  }
}
