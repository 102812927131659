@import './colors.scss';
@import './breakpoints.scss';

.slider {
  margin-bottom: 30px;
}

.slider-control-bottomcenter {
  & button {
    width: 20px;
  }
}
