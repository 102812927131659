@import './colors';

.input{
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 16px 40px 0 20px;
  font-size: 18px;
  background: transparent;
  border: 1px solid $blue;
  border-radius: 10px;
  outline: none;

  &:focus+label {
    color: $blue;
    top: 4px;
    font-size: 14px;
    z-index: 0;
  }
}

.input_box {
  position: relative;
  z-index: 0;
  height: 50px;
  margin-bottom: 25px;
}

.input_box_create {
  @extend .input_box;
  margin-bottom: 15px;

  & .input {
    padding-right: 25px;
  }
}

.not_active {
  & .input {
    border: 1px solid $grey;

    &:focus {
      border: 1px solid $blue;
    }
  }
}

.is_error {
  input, textarea, .input, .textarea {
    border: 1px solid $red;

    &:focus+label {
      color: $red;
    }
  }

  label {
    color: $red;
  }
}

.textarea {
  @import './scrollbar';
  @extend .input;
  height: 95px;
  width: 100%;
  margin-top: 20px;
  padding-top: 0;
  border: none;
  resize: none;
  font-family: 'SFUIDisplay-Regular';

  &:focus ~ .textarea_box {
    border: 1px solid $blue;
  }
}

.textarea_box {
  @extend .input_box_create;
  height: 120px;
  border: 1px solid $blue;
  border-radius: 10px;
}

.textarea_box_not_active {
  @extend .textarea_box;
  border: 1px solid $grey;

  &:focus-within {
    border: 1px solid $blue;
  }
}

.textarea_box_is_error {
  @extend .textarea_box;
  border: 1px solid $red;

  & label {
    color: $red;
  }

  textarea:focus+label {
    color: $red;
  }
}

.textarea_box_resizable {
  @extend .input_box_create;
  height: auto;
  border: 1px solid $blue;
  border-radius: 10px;
}

.textarea_box_resizable_not_active {
  @extend .textarea_box_resizable;
  border: 1px solid $grey;
}

.textarea_box_resizable_is_error {
  @extend .textarea_box_resizable;
  border: 1px solid $red;

  & label {
    color: $red;
  }
}

.textarea_box_extensible {
  @extend .input_box_create;
  border: 1px solid $blue;
  border-radius: 10px;
  height: auto;
  padding-bottom: 5px;
}

.textarea_box_extensible_not_active {
  @extend .textarea_box_extensible;
  border: 1px solid $grey;
}

.textarea_box_extensible_is_error {
  @extend .textarea_box_extensible;
  border: 1px solid $red;

  & .label {
    color: $red;
  }

  & .label__with_value {
    color: $red;
  }
}

.textarea_autoheight {
  @extend .textarea;

  height: auto;
  position: static;
}

.label{
  display: block;
  position: absolute;
  top: 14px;
  left: 20px;
  font-size: 18px;
  z-index: -1;
  transition: all 200ms;
  color: $grey;
  user-select: none;
}

.label__with_value {
  @extend .label;
  color: $blue;
  top: 4px;
  font-size: 14px;
  z-index: 2;
}


.autocomlete_off {
  &::-webkit-textfield-decoration-container,
  &::-webkit-caps-lock-indicator,
  &::-webkit-credentials-auto-fill-button {
    background-color: transparent;
  }
}

.safari_input {
  input {
    &::-webkit-textfield-decoration-container,
    &::-webkit-caps-lock-indicator,
    &::-webkit-credentials-auto-fill-button {
      background-color: transparent;
    }
  }
}

.eye_box {
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 2;
}

.google_dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  outline: none;
  z-index: 20;
}

.z_top {
  z-index: 1;
}

.location {
  width: 80%;
  padding: 0 0 5px 20px;
  border: none;
  resize: none;

  &::placeholder {
    font-size: 18px;
    color: $grey;
    font-family: 'SFUIDisplay-Semibold';
  }

  &_wrapper {
    position: relative;
    margin-bottom: 40px;
  }

  &_container {
    position: relative;
    border-bottom: 2px solid $blue;
  }
}

.autocomplete {
  position: absolute;
  top: 34px;
  width: 100%;
  max-height: 352px;
  overflow: auto;
  z-index: 2;
  background-color: white;

}

.autocomplete_loading {
  display: block;
  width: 90%;
  color: $grey;
  padding: 10px 0 10px 20px;
}

.autocomplete_item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(59, 60, 75, 0.1);
    background-color: rgba(46, 118, 234, 0.15);

    .autocomplete_link {
      color: $graphite;
    }

    .autocomplete_icon {
      fill: $graphite;
    }

    .autocomplete_icon_person {
      fill: $graphite;
      stroke: $graphite;
    }
  }
}

.autocomplete_link {
  display: block;
  width: 90%;
  padding: 10px;
  color: $grey;
  text-align: left;
}

.autocomplete_icon {
  width: 16px;
  height: 16px;
  fill: $grey;
}

.autocomplete_icon_person {
  width: 13px;
  height: 13px;
  fill: $grey;
  stroke: $grey;
  stroke-width: 2;
}

.autocomplete_venue {
  @extend .autocomplete;
  top: 50px;

  .autocomplete_subtext {
    padding-left: 10px;
    color: $grey;
    font-size: 12px;
  }

  .autocomplete_link {
    color: $graphite;
  }

  .autocomplete_info {
    width: 90%;
  }
}

.venue {
  color: $grey;
  padding: 10px 0 10px 20px;
}

.venue_title {
  padding-left: 20px;
}

.venue_container {
  position: relative;
}

.textarea_resizable {
  @extend .textarea;
  position: static;
  min-height: 200px;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
}

.pin_button {
  display: block;
  width: 100%;
  padding: 10px;
  color: $grey;
  text-align: left;

  &:hover {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(59, 60, 75, 0.1);
    background-color: rgba(46, 118, 234, 0.15);
    color: $graphite;
  }
}

.error_text {
  color: $red;
}
