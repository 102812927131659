@import './colors.scss';
@import './breakpoints.scss';

$aspect-ratio: 9 / 16;
$column_width: 0.25;
$column_width_big: 0.45;

.title {
  margin-bottom: 10px;
  padding-right: 30px;
  font-size: 30px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $graphite;
  letter-spacing: 0.03em;
  word-break: break-word;

  &::first-letter {
    text-transform: uppercase;
  }
}

.title_safari {
  @extend .title;
  letter-spacing: normal;
}

.name {
  @extend .title;
  margin-bottom: 30px;
}

.name_safari {
  @extend .name;
  letter-spacing: normal;
}

.style {
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $grey;

  &::after {
    display: inline-block;
    content: '';
    margin: 4px 5px;
    border-radius: 50%;
    border: 2px solid $grey;
  }

  &:last-child::after {
    display: none;
  }
}

.styles_container {
  margin-bottom: 30px;
  letter-spacing: 0.02em;
  line-height: 24px;
}

.styles_container_safari {
  @extend .styles_container;
  letter-spacing: normal;
}

.text_dark {
  display: block;
  margin-bottom: 5px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: $dark_grey;

  &.text_extrasmall {
    font-size: 12px;
  }
}

.text_light {
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: $grey;

  &.text_small {
    font-size: 14px;
  }

  span {
    font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: $grey;
  }
}

.info_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  svg {
    width: 30px;
    height: 34px;
    fill: $grey;
  }
}

.info {
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
  word-break: break-word;

  &_one_row {
    @extend .info;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5px;
  }
}

.info_ticket {
  @extend .info;
  padding-left: 0;
  font-size: 20px;

  .text_dark {
    margin-bottom: 0;
  }
}

.photos_container {
  position: relative;
}

.slide {
  width: 100%;
  padding-top: calc(100% * #{$aspect-ratio});
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
  background: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: white;
  min-height: calc(100vw * #{$column_width} * #{$aspect-ratio});

  @include breakpoint(lg) {
    min-height: calc((100vw * #{$column_width} - 60px) * #{$aspect-ratio});
  }

  @include breakpoint(md_down) {
    min-height: calc((100vw * #{$column_width_big} - 60px) * #{$aspect-ratio});
  }

  @include breakpoint(xs) {
    min-height: calc((100vw - 20px) * #{$aspect-ratio});
  }
}

.slide_single {
  composes: slide;
  margin-bottom: 30px;
  cursor: pointer;
}

.author {
  width: 80%;

  &_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .text_dark {
    margin: 0;
  }
}

.avatar {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  background: no-repeat;
  background-size: cover;
  position: relative;
  margin-right: 20px;
  flex-shrink: 0;
}

.subtitle {
  composes: title;
  font-size: 24px;
  padding-right: 0;

  &::before {
    content: '';
    display: block;
    border-bottom: 1px solid $light_grey;
    margin-bottom: 15px;
    margin-top: 30px;
  }
}

.likes {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $dark_white;

  &_icon {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &_quantity {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: $graphite;
  }
}

.calendar_icon {
  & > path  {
    stroke: $grey;
    stroke-opacity: 0.7;
  }
}

.info_icon {
  & > path  {
    stroke: $grey;
    stroke-opacity: 0.3;
  }
}

.day {
  font-size: 18px;
  color: $blue;

  &:not(:last-child)::after {
    content: '/';
    display: inline;
    font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  }
}

.day_selected {
  @extend .day;

  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $blue;
}

.day_disabled {
  @extend .day;
  color: $dark_grey;
}

.day_all {
  padding: 2px 6px;
  margin-left: 20px;
  font-size: 14px;
  color: $grey;
  border: 1px solid #909bb1;
  border-radius: 4px;
  text-align: center;
}

.venue {
  &:hover .text_dark {
    color: $blue;
  }
}

.arrow_wrapper {
  width: 30px;
  height: 30px;
  background-color: $blue;
  border-radius: 50%;
  text-align: center;
  flex-shrink: 0;

  .arrow {
    width: 14px;

    path {
      fill: white;
    }
  }
}

.wrapper {
  width: 100%;
}

.without_items {
  padding: 20px;
  color: $grey;
}

.remove_error {
  padding: 10px 0;
  font-size: 12px;
  text-align: center;
  color: $grey;
}

.fee_icon {
  color: $grey;
  font-size: 30px;
}

.text_small_grey {
  font-size: 12px;
  text-align: center;
  color: $grey;
}

.pad_bottom_20 {
  padding-bottom: 20px;
}

.grey_link {
  font-size: 12px;
  color: $grey;
  text-decoration: underline;
}

.sticky_wrapper {
  height: 70px;
  padding: 10px 0 20px;
  box-sizing: border-box;
}

.stick {
  position: fixed;
  width: 27%;
  min-width: 320px;
  padding: 0 38px 0 30px;
  box-sizing: border-box;
  bottom: 85px;
  right: 0;
  z-index: 2;

  @include breakpoint(md_down) {
    width: 45%;
  }

  @include breakpoint(xs) {
    width: 100%;
    bottom: 10px;
    padding: 0 10px;
  }
}
