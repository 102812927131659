@import '../../styles/colors';
@import '../../styles/breakpoints';
@import '../../styles/main_grid.module.scss';

.wrapper {
  @extend .side_bar_wide;

  padding: 0;

  @include breakpoint(xs) {
    position: absolute;
    z-index: 4;
    width: 100%;
    min-height: calc(100vh - 81px);
  }
}

.title {
  padding: 10px 0;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 30px;
  color: $graphite;

  &::first-letter {
    text-transform: uppercase;
  }
}

.avatar_box {
  display: flex;
  justify-content: center;
  position: relative;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: no-repeat;
  background-size: cover;
  position: relative;
}

.pin_icon {
  width: 15px;
  fill: $grey;
}

.grey_text {
  color: $grey;
}

.sub_wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
}

.friend_button {
  background-color: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  box-shadow: 0 0 12px 0 rgba(59, 60, 75, 0.08);
}

.add_icon,
.added_icon,
.pending_icon {
  width: 30px;
}

.add_icon {
  fill: $blue;
}

.pending_icon {
  fill: $grey;
}

.added_icon {
  fill: $green;
}

.dance_styles {
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  color: $blue;
  padding-bottom: 15px;
}

.sub_title {
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-bottom: 5px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.divider {
  border-top: 1px solid $light_grey;
  margin: 30px 0;
}

.label {
  color: $grey;
  font-size: 14px;
  padding-bottom: 5px;
}

.cancel_button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.saved_title {
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: $grey;
  margin-bottom: 30px;
}

.saved_section {
  background-color: #f8f8fa;
  padding: 30px;
  box-shadow: inset 0px 11px 8px -4px rgba(0, 0, 0, 0.04);
}

.info_section {
  padding: 30px;
}

.edit_button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 5px 0 5px;
}

.pencil_icon {
  width: 20px;
  stroke-width: 1px;
  stroke: $grey;
  fill: $grey;

  &:hover {
    fill: $dark_blue;
    stroke: $dark_blue;
  }
}

.avatar_row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 20px;
}
