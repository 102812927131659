@import '../../styles/colors';

.close {
  position: absolute;
  top: 20px;
  right: 5px;
  padding: 10px;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-size: cover;
  margin-right: 20px;
  flex-shrink: 0;
  background-color: $light_grey;
}

.cohoster_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.hoster_status {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  margin-left: auto;
}

.dots {
  width: 5px;
  fill: $blue;
  margin-left: 10px;
}

.submenu {
  position: absolute;
  top: 0;
  right: 0;
}

.dots_button {
  line-height: 0;
}

.secondary_name {
  padding-top: 3px;
  color: $grey;
  font-size: 12px;
}

.plus_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: $blue;
  flex-shrink: 0;
  margin-left: auto;
  transition: background-color 200ms ease;

  &:hover {
    background-color: $dark_blue;
  }
}

.plus_icon {
  width: 14px;
  fill: #fff;
}

.name_box {
  padding-right: 5px;
}

.grey_text {
  color: $grey;
  padding-bottom: 10px;
}
