@import './breakpoints';
@import './sizes';

.base_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 80px);
  background-color: #fff;
  transition: width 300ms ease;
}

.wrapper_fixed_bottom-enter {
  width: 0;

  @extend .base_wrapper;

  @include breakpoint(xs) {
    width: 100%;
  }
}

.wrapper_fixed_bottom-enter-active {
  width: 27%;

  @extend .base_wrapper;

  @include breakpoint(xs) {
    width: 100%;
  }
}

.wrapper_fixed_bottom-enter-done {
  @extend .base_wrapper;

  width: 27%;
  min-width: 320px;

  @include breakpoint(md_down) {
    width: 45%;
  }

  @include breakpoint(xs) {
    width: 100%;
  }
}

.wrapper_fixed_bottom-exit {
  width: 27%;

  @extend .base_wrapper;
}

.wrapper_fixed_bottom-exit-active {
  width: 0;

  @extend .base_wrapper;
}


.burger_menu_wrapper {
  position: absolute;
  display: flex;
  left: 0;
  top: 80px;
  width: 100%;
  transition: transform 300ms ease;
}

.burger_menu_wrapper-enter {
  @extend .burger_menu_wrapper;

  transform: translate(-100%, 0);
}

.burger_menu_wrapper-enter-active {
  @extend .burger_menu_wrapper;

  transform: translate(0, 0);
}

.burger_menu_wrapper-exit {
  @extend .burger_menu_wrapper;

  transform: translate(0 ,0);
}

.burger_menu_wrapper-exit-active {
  @extend .burger_menu_wrapper;

  transform: translate(-100% ,0);
}

.side_bar_wide {
  @import './scrollbar.scss';

  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.07);
  padding: 30px;
  height: calc(100vh - #{$header_height});
  overflow: auto;
  background-color: #fff;
  transition: width 300ms ease;

  @include breakpoint(xs) {
    width: 100%;
    flex-shrink: 0;
    height: auto;
    padding: 30px 10px;
  }
}

.side_bar_wide-enter-done {
  @extend .side_bar_wide;

  width: 35%;
  min-width: 320px;

  @include breakpoint(md_down) {
    width: 45%;
  }

  @include breakpoint(xs) {
    width: 100%;
  }
}

.side_bar_wide-enter {
  width: 0;

  @extend .side_bar_wide;

  @include breakpoint(xs) {
    width: 100%;
  }
}

.side_bar_wide-enter-active {
  width: 35%;

  @extend .side_bar_wide;

  @include breakpoint(xs) {
    width: 100%;
  }
}

.side_bar_wide-exit {
  width: 35%;

  @extend .side_bar_wide;
}

.side_bar_wide-exit-active {
  width: 0;

  @extend .side_bar_wide;
}

.side_bar {
  @import './scrollbar.scss';

  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.07);
  padding: 30px;
  height: calc(100vh - #{$header_height});
  overflow: auto;
  background-color: #fff;
  transition: width 300ms ease;
}

.side_bar-enter-done {
  @extend .side_bar;

  width: 27%;
  min-width: 320px;

  @include breakpoint(md_down) {
    width: 45%;
  }

  @include breakpoint(xs) {
    width: 100%;
    flex-shrink: 0;
    height: auto;
    padding: 30px 10px;
  }
}

.side_bar-enter {
  width: 0;

  @extend .side_bar;
}

.side_bar-enter-active {
  width: 27%;

  @extend .side_bar;

  @include breakpoint(md_down) {
    width: 45%;
  }

  @include breakpoint(xs) {
    width: 100%;
    flex-shrink: 0;
    height: auto;
    padding: 30px 10px;
  }
}

.side_bar-exit {
  width: 27%;

  @extend .side_bar;
}

.side_bar-exit-active {
  width: 0;

  @extend .side_bar;
}
