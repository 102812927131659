@import '../../styles/colors.scss';

.title {
  margin: 35px 0 25px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: $graphite;
}

.subtitle {
  @extend .title;
  margin-bottom: 20px;
  font-size: 18px;

  &::before {
    content: '';
    display: block;
    border-bottom: 1px solid $light_grey;
    margin-bottom: 20px;
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.body {
  width: 80%;

  & span {
    font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: $grey;
  }
}

.avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  background: no-repeat;
  background-size: cover;
  position: relative;
  margin-right: 20px;
  flex-shrink: 0;
}

.text_dark {
  margin-bottom: 12px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: $dark_grey;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  max-width: 90%;
}

.text_light {
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: $grey;
}

.menu_button {
  align-self: flex-start;
}

.comment_info {
  color: $grey;
  font-size: 11px;
  padding-top: 10px;
  text-align: right;
}

.more_container {
  text-align: center;
}

.more_btn {
  font-size: 16px;
  color: $blue;

  &::after {
    display: inline-block;
    content: '';
    width: 5px;
    height: 5px;
    margin: 0px 10px;
    border-bottom: 1px solid $blue;
    border-right: 1px solid $blue;
    transform: rotate(45deg) translate(0px, -3px);
    transition: all linear 0.4s;
  }
}

.comment_form {
  margin-bottom: 30px;
}
