@import '../../../styles/colors';

.tooltip {
  display: none;
  position: absolute;
  top: -10px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 2px 4px 20px -6px rgba(0,0,0,0.49);
  padding: 0 2px;
  white-space: nowrap;
  z-index: 2;
  font-size: 14px;
}

.friend_link {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: no-repeat;
  background-size: cover;
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}

.friends_wrapper {
  padding: 10px 0;
}

.toggle_button {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: background-color 0.3s;
  background-color: $blue;
  color: #fff;
  text-align: center;
  line-height: 44px;
  cursor: pointer;

  &:hover {
    background-color: $dark_blue;
  }
}

.title {
  margin: 30px 0;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: $graphite;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
