@import '../../styles/colors';
@import '../../styles/breakpoints';

.avatar {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  margin-left: 12px;
  flex-shrink: 0;
  background-color: $light_grey;
}

.dropdown_wrapper {
  position: relative;
}

.dropdown_title {
  display: flex;
  align-items: center;
  margin-left: 32px;
  cursor: pointer;

  @include breakpoint(xs) {
    margin-left: 10px;
  }

  &::after {
    @include breakpoint(md) {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 0 2px 0 0;
      transform: rotate(135deg);
      border-top: 2px solid $dark_grey;
      border-right: 2px solid $dark_grey;
      margin-left: 10px;
      transition: transform 200ms;
    }
  }
}

.dropdown_menu {
  position: absolute;
  right: 0;
  top: 60px;
  min-width: 180px;
  background-color: #fff;
  box-shadow: 0px 8px 18px 0 rgba(59, 60, 75, 0.15);
  border-radius: 0 0 5px 5px;
  z-index: 2;
  overflow: hidden;

  .menu_item {
    padding: 16px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;

    &:hover {
      color: #fff;
      background-color: $dark_blue;
    }
  }
}

.is_open {
  .dropdown_title::after {
    transform: rotate(225deg);
  }
}

.notification_counter {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $blue;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

.notification_counter_in_menu {
  @extend .notification_counter;

  top: 15px;
  right: 10px;
}
