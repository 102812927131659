@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.photo {
  position: relative;
  width: 86px;
  height: 86px;
  box-sizing: border-box;
  margin: 0 2px 20px;
  overflow: hidden;
  border-radius: 10px;
  border: solid 1px $grey;
  background: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: white;

  @include breakpoint(xs) {
    width: 70px;
    height: 70px;
  }
}

.photo_promo {
  @extend .photo;
  width: 100%;
  padding-top: 56.25%;
  margin: 0 auto 20px;
}

.camera {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 23px;
  fill: $grey;
}

.icon {
  & path {
    fill: white;
    stroke: $grey;
    stroke-width: 3;
  }
}

.icon_wrapper {
  position: absolute;
  z-index: 1;
  top: 7px;
  right: 7px;
  width: 15px;
  height: 15px;
  cursor: pointer;

  &_left {
    @extend .icon_wrapper;
    top: 5px;
    left: 7px;
    width: 20px;
    height: 20px;
  }
}
