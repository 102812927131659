@import '../../styles/breakpoints';
@import '../../styles/colors';

// Important! Always set the container height explicitly
.map_container {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;

  @include breakpoint(xs) {
    order: -1;
    height: 350px;
    flex: 0 0 350px; // flex-basis for fire-fox map rendering
  }
}

.btn_row {
  display: flex;
  padding: 0 0 25px 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;

  @include breakpoint(xs) {
    left: 0;
    transform: none;
    padding: 0 0 25px 20px;
  }

  button {
    &:not(:last-child) {
      margin-right: 8px;
    }

    @include breakpoint(iphone_5) {
      padding: 10px 20px;
    }
  }
}

.checkbox_container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(59, 60, 75, 0.1);
  cursor: pointer;

  input {
    display: none;
  }

  .title {
    padding: 0;
  }
}

.checkmark {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 1px solid $blue;
  margin-right: 8px;
  cursor: pointer;
  flex-shrink: 0;
}

.checkmark_icon {
  fill: $blue;
  height: 15px;
  width: 15px;
}

.checkmark_text {
  font-size: 14px;
}
