@import './colors.scss';
@import './breakpoints.scss';
@import './sizes.scss';

.container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  min-height: $banner_height;
  padding: 10px;
  background-color: $beige;
}

.inactive_container {
  @extend .container;
  min-height: $inactive_banner_height;

  @include breakpoint(small_screen) {
    min-height: $inactive_banner_height_small_screen;
  }
}

.text {
  color: $grey;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.link {
  color: $blue;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  text-decoration: underline;
}

.warning {
  display: inline-block;
  height: 18px;
  fill: $orange;
  vertical-align: bottom;
  padding-right: 5px;
}
