@import '../../styles/colors';
@import '../../styles/breakpoints';

.title_row {
  display: flex;
  align-items: center;
  padding-right: 15px;
  margin-bottom: 40px;

  @include breakpoint(xs) {
    padding-right: 40px;
  }
}

.title {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 30px;
  padding-right: 10px;
  line-height: 24px;
}

.label {
  font-size: 14px;
  color: $grey;
  margin-bottom: 7px;
}

.text {
  font-size: 18px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid $light_grey;
  min-height: 21px;
}

.empty_value {
  color: $orange;
}

.text_input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px $grey;
  padding: 7px 14px 8px;
  color: $grey;
  margin-bottom: 30px;
}

.input_label {
  display: block;
  margin-bottom: 7px;
  font-size: 14px;
}

.btns_box {
  display: flex;
  justify-content: space-between;

  button {
    width: 45%;
    padding: 10px 5px;
    box-sizing: border-box;
  }
}

.btns_center {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}

.avatar_row {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 20px;
}

.edit_button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 5px 0 5px;
}

.pencil_icon {
  width: 20px;
  stroke-width: 1px;
  stroke: $grey;
  fill: $grey;

  &:hover {
    fill: $dark_blue;
    stroke: $dark_blue;
  }
}

.progress_bar_row {
  margin-bottom: 20px;
}

.view_link {
  position: absolute;
  left: 0;
  bottom: 0;
  color: $grey;
  text-decoration: underline;

  &:hover {
    color: $dark_grey;
  }
}
