@import '../../../styles/colors.scss';

.info {
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
  word-break: break-word;

  &_one_row {
    @extend .info;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
}

.info_ticket {
  @extend .info;
  padding-left: 0;
  font-size: 20px;

  .text_dark {
    margin-bottom: 0;
  }
}

.text_dark {
  display: block;
  margin-bottom: 5px;
  padding: 5px 0;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: $dark_grey;

  &.text_extrasmall {
    font-size: 12px;
  }
}

.text_light {
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: $grey;

  &.text_small {
    font-size: 14px;
  }

  span {
    font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: $grey;
  }
}

.day {
  font-size: 18px;

  &:not(:last-child)::after {
    content: '/';
    display: inline;
  }
}

.day_selected {
  @extend .day;

  color: $blue;
}
