@import '../../styles/colors';
@import '../../styles/breakpoints';


.user_point {
  position: relative;
  z-index: -1;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background-color: rgba(0, 120, 242, 0.12);
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    opacity: 0.25;
    background-color: rgba(0, 120, 242, 0.5);
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
    background-color: $blue;
    box-sizing: border-box;
  }
}

.marker {
  width: 30px;
  fill: $blue;
  transform: translate(-15px, -30px);
  transition: all 200ms;
}

.marker_active {
  width: 40px;
  fill: $dark_blue;
  transform: translate(-20px, -40px);
}

.active_link {
  svg {
    @extend .marker_active,
  }
}

.cluster {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid $blue;
  background-color: #fff;
  transform: translate(-50%, -50%);
  text-align: center;
  box-sizing: border-box;
  padding-top: 9px;
}

.multi_marker {
  cursor: pointer;
}

.counter {
  position: absolute;
  top: -17px;
  left: 1px;
  min-width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  border: 1px solid $blue;
  box-sizing: border-box;
  padding: 0 2px;
  font-size: 14px;
}

.marker_menu {
  @import '../../styles/scrollbar';
  position: absolute;
  top: 5px;
  left: -10px;
  z-index: 30;
  min-width: 150px;
  max-width: 250px;
  max-height: 250px;
  overflow: auto;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px 0;

  @include breakpoint(xs) {
    max-height: 180px;
  }
}

.menu_link {
  display: block;
  padding: 5px 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: #fff;
    background-color: $blue;
  }
}

.center_pin {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 34px;
  transform: translate(-50%, -100%);
}

.pulsation {
  position: absolute;
  top: -12px;
  left: -21px;
  z-index: -1;
  width: 40px;
  height: 20px;
  background-color: rgba(0, 120, 242, 0.2);
  border-radius: 50%;
  border: 1px solid $blue;

  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }

  30% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}
