@import './colors.scss';
@import './breakpoints.scss';

.button {
  cursor: pointer;
}

.btn_radius {
  border-radius: 10px;
  padding: 10px 35px;
  text-align: center;
  transition: background-color 0.3s;
}

.btn_white {
  @extend .btn_radius;

  background-color: #fff;
  border: 1px solid $blue;
  color: $blue;
  font-size: 16px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;

  &:hover {
    background-color: $blue;
    color: #fff;
  }
}

.btn_blue {
  @extend .btn_radius;

  background-color: $blue;
  border: 1px solid $blue;
  color: #fff;
  font-size: 15px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;

  &:hover {
    background-color: $dark_blue;
  }
}

.btn_uppercase {
  text-transform: uppercase;
}

.btn_black {
  @extend .btn_radius;

  text-align: center;
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 15px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;

  &:hover {
    @include breakpoint(sm) {
      background-color: $dark_grey;
      border-color: $dark_grey;
    }
  }
}

.btn_red {
  @extend .btn_radius;

  background-color: $red;
  border: 1px solid $red;
  color: #fff;
  font-size: 15px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;

  &:hover {
    background-color: $dark_red;
    border-color: $dark_red;
  }
}

.sign_in_button {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: -20px 0 -20px 20px;

  @include breakpoint(sm) {
    padding-left: 20px;
    border-left: 1px solid #e4e4e4;
  }

  span {
    display: block;
    margin-right: 8px;
  }

  svg {
    fill: $blue;
  }

  &:hover {
    span {
      color: $dark_blue;
    }

    svg {
      fill: $dark_blue;
    }
  }
}

.btn_in_modal {
  width: 100%;
  border-radius: 5px;
  min-height: 50px;
  box-sizing: border-box;
}

.btn_google,
.btn_fb,
.btn_apple {
  @extend .btn_in_modal;

  padding: 10px 35px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  svg {
    margin-right: 15px;
  }
}

.btn_google {
  border: 1px solid $light_grey;

  &:hover {
    border-color: $dark_blue;
  }
}

.btn_fb {
  background-color: #3c5a9a;
  color: #fff;

  &:hover {
    background-color: $dark_blue;
  }
}

.btn_apple {
  background-color: #000;
  color: #fff;

  &:hover {
    background-color: $dark_grey;
  }
}

.apple_logo {
  width: 18px;
  fill: #fff;
}

.btn_ok {
  border-radius: 5px;
  min-height: 50px;
  box-sizing: border-box;
  padding: 1px 48px;
  background-color: $blue;
  color: #fff;
  font-size: 15px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  transition: background-color 0.3s;

  &:hover {
    background-color: $dark_blue;
    color: #fff;
  }
}

a.btn_ok {
  display: block;
  line-height: 50px;
}

.buttons_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 auto;

  .btn_radius {
    width: 45%;
    max-width: 185px;
    padding: 10px 0;
  }
}

.buttons_row_with_margin {
  @extend .buttons_row;
  margin-bottom: 30px;
}

.buttons_container {
  padding: 22px 0;
}

.btn_wide {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0 20px;
}

.fullwidth {
  width: 100%;
  box-sizing: border-box;
}

.location_button {
  position: absolute;
  bottom: 20px;
  right: 8px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(59, 60, 75, 0.1);
  background-color: #ffffff;
}

.location_icon {
  width: 20px;
  height: 20px;
}

.fixed_buttons {
  background-color: #fff;
  padding: 10px 28px 10px 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 18px 0 rgba(59, 60, 75, 0.1);

  @include breakpoint(xs) {
    width: 100%;
    padding: 10px;
  }
}

.fixed_buttons_xs {
  background-color: #fff;
  padding: 10px 28px 10px 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 18px 0 rgba(59, 60, 75, 0.1);

  @include breakpoint(xs) {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    padding: 5px 10px 15px;
  }
}

.fixed_icons {
  @extend .fixed_buttons;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 79px;
  padding: 18px 40px;
  z-index: 5;

  &_title {
    font-size: 12px;
    font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  }

  &_container {
    text-align: center;
  }
}

.cancel_button {
  position: absolute;
  top: 20px;
  right: 5px;
  padding: 10px;
}

.close_round {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: $grey;
  border-radius: 50%;

  &:hover {
    background-color: $blue;
  }
}

.close_round_wrapper {
  position: absolute;
  right: 40px;
  padding: 2px 10px 10px 10px;
}

.close_round_wrapper_checkin {
  @extend .close_round_wrapper;
  right: 0;
}

.close_round_svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;

  path {
    fill: white;
  }
}

.btn_min_170 {
  min-width: 170px;
  padding-right: 1px;
  padding-left: 1px;
  box-sizing: border-box;
  line-height: 19px;
}

.card_btn {
  border-radius: 5px;
  padding: 10px 20px;
  min-width: 85px;
  text-align: center;
  background-color: $blue;
  border: 1px solid $blue;
  color: #fff;
  font-size: 15px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;

  &:hover {
    background-color: $dark_blue;
  }
}

.link_pass_btn {
  display: flex;
  width: 100%;
  min-height: 50px;
  padding: 8px 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  color: $grey;
  border-radius: 10px;
  border: 1px solid $grey;
  font-size: 18px;
}

.link_pass_icon {
  width: 15px;
  fill: $grey;
}

.btn_box {
  margin: 20px auto;
}


.btn_select_date {
  font-size: 13px;
  color: #fff;
  background-color: $grey;
  border-radius: 5px;
  padding: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: $dark_grey;
  }
}

.extra_wide_container {
  margin: 0 -10px;

  @include breakpoint(xs) {
    margin: 0;
  }
}

.arrow_up {
  width: 15px;
  fill: $blue;
}

.arrow_up_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 3px 18px 0 rgba(59, 60, 75, 0.08);
  transition: all 200ms;
  cursor: pointer;

  @include breakpoint(sm) {
    right: unset;
    left: 37%;
  }

  @include breakpoint(md) {
    right: unset;
    left: 23%;
  }

  &:hover {
    box-shadow: 0px 3px 18px 0 rgba(59, 60, 75, 0.18);
    transform: scale(1.07);
  }
}

.btn_border {
  @extend .btn_ok;

  color: $blue;
  background-color: transparent;
  border: 1px solid $blue;
}

.btn_add {
  font-size: 18px;
  color: $blue;
  transition: color 200ms ease;

  &:hover {
    color: $dark_blue;
  }
}

.btn_remove {
  font-size: 18px;
  color: $red;
  transition: color 200ms ease;

  &:hover {
    color: $dark_red;
  }

  &.disabled {
    color: $grey;
    cursor: default;
  }
}

.btn_disabled {
  @extend .btn_radius;

  color: #fff;
  font-size: 15px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  background-color: $grey;
  border: 1px solid $grey;
  pointer-events: none;
}
