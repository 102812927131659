@import '../../../styles/colors.scss';

.tab_wrapper {
  display: flex;
  justify-content: space-between;
}

.tab_item {
  flex: 0 auto 50%;
  text-align: center;
  color: $grey;
  font-size: 18px;
  padding: 25px 10px;
  cursor: pointer;

  &:hover {
    border-bottom: 3px solid $dark_blue;
    color: $dark_blue;
  }
}

.active {
  color: $blue;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  border-bottom: 3px solid $blue;
}
