@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.picker_wrapper {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.confirm {
  width: 16px;
  fill: $grey;

  &:hover {
    fill: $blue;
  }
}

.icon {
  stroke: $grey;

  &_active {
    stroke: $blue;
  }

  &_error {
    stroke: $red;
  }
}
