$blue: #0077ee;
$dark_blue: #005edf;
$light_blue: #cce4fc;
$grey: #909bb1;
$dark_grey: #3d4046;
$light_grey: #e4e7ec;
$light_grey_hover : #cbced4;
$graphite: #25272b;
$red: #ff4839;
$dark_red: #ec220f;
$green: #00f27a;
$dark_green: #00db93;
$dark_white: #f7f8f8;
$beige: #fcedce;
$orange: #fec151;
