@import '../../styles/colors';

.form {
  position: relative;
}

.avatar {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  background: no-repeat;
  background-size: cover;
  position: relative;
}

.edit_photo {
  position: absolute;
  top: 140px;
  bottom: 0;
  left: 0;
  background-color: rgba(2, 120, 238, 0.75);
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: $dark_blue;
  }

  svg {
    display: block;
    fill: #fff;
    width: 23px;
    margin: auto;
  }
}

.button_wrapper {
  position: absolute;
  top: 140px;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.btn_apply,
.btn_cancel {
  display: block;
  color: #fff;
  width: 50%;
  box-sizing: border-box;
  padding-top: 10px;
}

.btn_apply {
  background-color: rgba(2, 120, 238, 0.75);
  padding-right: 10px;
  text-align: right;

  &:hover {
    background-color: rgba(2, 120, 238, 1);
  }
}

.btn_cancel {
  background-color: rgba(255, 72, 57, 0.75);
  padding-left: 10px;
  text-align: left;

  &:hover {
    background-color: rgba(255, 72, 57, 1);
  }
}

.crop {
  width: 15px;
  height: 15px;
  padding: 5px;
  cursor: pointer;

  & path {
    fill: $grey;
  }

  &_wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.error {
  color: $red;
  font-size: 14px;
}
