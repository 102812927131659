@import '../../../styles/colors.scss';

.StripeElement {
  box-sizing: border-box;
  padding: 5px 5px 2px;
  border-bottom: 1px solid #c1daff;
}

.StripeElement--invalid {
  border-color: $red;
}
