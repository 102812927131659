@import '../../../styles/colors';

.qr_code {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.title {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
  margin: 0 auto;
}

.sub_title {
  color: $grey;
  padding: 6px 0;
}

.ticket_info {
  text-align: left;
  padding-top: 40px;
}

.info_item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid $light_grey;
  font-size: 20px;
}

.info_list {
  padding: 0 0 10px 53px;
}

.icon_box {
  min-width: 28px;
  margin-right: 25px;
  text-align: center;
}

.ticket_icon {
  fill: $grey;
  width: 23px;
  height: 27px;
}

.person_icon {
  fill: $grey;
  width: 26px;
  height: 28px;
}

.calendar_icon {
  fill: $grey;
  width: 27px;
  height: 28px;
}

.pin_icon {
  fill: $grey;
  width: 23px;
  height: 28px;
}

.location {
  font-size: 20px;
}

.address {
  color: $dark_grey;
}

.printer_icon {
  width: 30px;
  fill: #000;
  transition: fill 200ms ease;

  &:hover {
    fill: $blue;
  }
}

.manual_link {
  font-size: 12px;
  color: $blue;
  padding-bottom: 10px;
  text-decoration: underline;

  &:hover {
    color: $dark_blue;
  }
}

.manual_container {
  padding: 20px 0;
}

.center_container {
  text-align: center;
}

.more_button {
  color: $blue;

  &::after {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-top: 1px solid $blue;
    border-right: 1px solid $blue;
    transform: rotate(135deg);
    margin: 3px 6px;
    transition: transform 200ms ease;
  }
}

.less_button {
  @extend .more_button;

  &::after {
    transform: rotate(-45deg) translate(0, 5px);
  }
}

.last_checkin {
  padding: 5px 0;
}

.qr_box {
  position: relative;
}

.checkmark_round_green {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 61px;
  background-color: $green;
}

.checkmark_round_red {
  @extend .checkmark_round_green;

  background-color: $red;
}

.checkmark {
  width: 24px;
  fill: #fff;
}
