@import '../../../styles/colors.scss';


.star {
  fill: $blue;
  width: 23px;
  height: 23px;
}

.dots {
  transform: rotate(90deg);
  width: 5px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}

.title {
  font-size: 12px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
}

.relative_container {
  position: relative;
}

.share_menu {
  position: absolute;
  z-index: 2;
  bottom: 70px;
  left: -5px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 4px 5px 16px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.share_link {
  display: block;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 200ms ease;
  background-color: #fff;
  text-align: center;

  &:hover {
    background-color: $dark_blue;

    .fb_icon,
    .twitter_icon,
    .mail_icon {
      stroke: #fff;
      fill: #fff;
    }

    .link_icon {
      fill: #fff;
    }
  }
}

.twitter_icon,
.mail_icon {
  width: 20px;
  fill: $blue;
  stroke: $blue;
  stroke-width: 6px;
}

.fb_icon {
  @extend .twitter_icon;

  width: 25px;
}

.link_icon {
  width: 16px;
  fill: $blue;
}

.tooltip {
  position: absolute;
  bottom: 0;
  font-size: 11px;
  padding: 0 3px;
  user-select: none;
}
