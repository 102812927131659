@import '../../styles/colors.scss';

.list_container {
  position: relative;
  padding-top: 20px;
  border-bottom: 1px solid $light_grey;
}

.list_title {
  width: 100%;
  text-align: left;
  font-size: 18px;
  padding-bottom: 20px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  color: $graphite;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 25px;
  right: 5px;
  width: 8px;
  fill: $graphite;
}

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.item_with_sublist {
  display: block;
}

.link {
  font-size: 16px;
  font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  color: $graphite;

  &:hover {
    font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  }
}

.sublist {
  padding-left: 20px;

  .item::before {
    display: inline-block;
    content: '';
    width: 5px;
    height: 5px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: $grey;
  }

  .link {
    color: $grey;
    flex: 1;
    padding: 0;
  }
}

.warning {
  height: 15px;
  fill: $orange;
  margin-right: 5px;
}
