@import '../../styles/breakpoints.scss';
@import '../../styles/colors';

.header {
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.03);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
  background-color: #fff;
  box-sizing: border-box;

  @include breakpoint(xs) {
    width: 100%;
    padding: 20px 10px;
    position: fixed;
    top: 0;
  }
}

.right_part {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.left_part {
  display: flex;
  align-items: center;
}

.logo_box {
  margin: 0 45px;
  position: relative;

  // &::after {
  //   content: 'beta';
  //   position: absolute;
  //   right: 20px;
  //   bottom: 0;
  //   font-size: 11px;
  //   color: $blue;
  // }

  @include breakpoint(xs) {
    margin: 0 0 0 25px;
  }
}

.search {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-left: 100px;
  color: $grey;
  text-align: left;
  font-size: 14px;
  transition: color 200ms ease;

  &:hover {
    color: $dark_grey;

    .search_icon_box {
      background-color: $dark_grey;
    }
  }
}

.search_mobile {
  padding: 10px 5px 0;
}

.search_icon_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: $grey;
  border-radius: 8px;
  margin-left: 5px;

  transition: background-color 200ms ease;
}

.search_icon {
  width: 16px;
  fill: #fff;
}

.header_link {
  font-size: 17px;
  color: $graphite;
  cursor: pointer;
  margin-left: 50px;

  @include breakpoint(xs) {
    margin-left: 20px;
  }
}
