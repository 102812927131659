@import './colors.scss';
@import './breakpoints';

.title {
  padding-bottom: 15px;
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 30px;
  color: $graphite;

  &::first-letter {
    text-transform: uppercase;
  }
}

.page_title {
  @extend .title;
  margin-bottom: 40px;
  padding-right: 30px;
}

.page {
  text-align: center;
  padding: 70px 10px;
}

.reset_page {
  @extend .page;
  box-sizing: border-box;
  max-width: 400px;
  margin: 0 auto;
}

.page_title_centered {
  @extend .title;
  margin: 0 auto 40px;
  text-align: center;
}

.secondary_title {
  @extend .title;

  font-size: 24px;
  padding: 20px 0;
}

.container {
  position: relative;
}

.close {
  position: absolute;
  top: 20px;
  right: 5px;
  padding: 10px;
}

.wrapper {
  margin: 0 auto;
  padding-bottom: 40px;
}

.page_wrapper {
  max-width: 900px;
  padding: 40px;
  margin: 0 auto;

  @include breakpoint(xs) {
    padding: 40px 10px;
  }
}

.paragraph {
  padding-bottom: 20px;
}

.list_circle {
  list-style-type: circle;
  padding-left: 15px;
  padding-bottom: 20px;
}
