@import '../../styles/colors';
@import '../../styles/breakpoints';

.title {
  font-family: 'SFUIDisplay-Bold', Arial, Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
  margin: 40px auto;
}

.input_box {
  position: relative;
  z-index: 0;
  height: 50px;
  margin-bottom: 25px;

  input{
    font-size: 18px;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: transparent;
    border: 1px solid $blue;
    border-radius: 10px;
    padding: 16px 40px 0 20px;
    box-sizing: border-box;

    &:focus+label {
      color: $blue;
      top: 4px;
      font-size: 14px;
      z-index: 2;
    }
  }

  label{
    display: block;
    position: absolute;
    top: 14px;
    left: 20px;
    font-size: 18px;
    z-index: -1;
    transition: all 200ms;
    color: $grey;
    user-select: none;

    &.with_value {
      color: $blue;
      top: 4px;
      font-size: 14px;
      z-index: 2;
    }
  }

  .eye_box {
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 2;
  }

  &.is_error {
    input {
      border: 1px solid $red;

      &:focus+label {
        color: $red;
      }
    }

    label {
      color: $red;
    }
  }
}

.terms_link {
  text-decoration: underline;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  margin-top: 25px;
}

.divider {
  text-transform: uppercase;
  color: $grey;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;

  &::before,
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid $light_grey;
    width: 94px;
    margin: 0 15px;
  }
}

.btn_margin_box {
  margin-bottom: 15px;
}

.forgot_password {
  text-decoration: underline;
  color: $grey;
  display: block;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  margin: 20px auto 30px;
}

.error_message {
  color: $red;
}

.form_text {
  line-height: 16px;
  margin-top: 20px;
}

.resend_success {
  margin-top: 20px;
}

.autocomlete_off {
  &::-webkit-textfield-decoration-container,
  &::-webkit-caps-lock-indicator,
  &::-webkit-credentials-auto-fill-button {
    background-color: transparent;
  }
}

.btns_row {
  display: flex;
  justify-content: space-around;

  button {
    @include breakpoint(xs) {
      padding: 10px 25px;
      white-space: nowrap;
    }
  }
}

.message {
  padding: 20px 0;
}

.message_box {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 0 20px;
  box-sizing: border-box;

  @include breakpoint(xs) {
    width: 300px;
    padding: 0 0 20px 0;
  }
}
