@import '../../styles/colors';

.close {
  position: absolute;
  top: 20px;
  right: 5px;
  padding: 10px;
}

.grey_text {
  color: $grey;
  padding-bottom: 10px;
}

.title_row {
  min-height: 30px;
}
