@import '../../styles/colors';

.loader_wrapper {
  width: 138px;
  height: 138px;
  position: fixed;
  z-index: 50;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {

  font-size: 10px;
  text-indent: -9999em;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border-top: 14px solid transparent;
  border-right: 14px solid $blue;
  border-bottom: 14px solid $blue;
  border-left: 14px solid transparent;
  animation: load3 1.4s infinite linear;
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
