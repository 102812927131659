@import '../../styles/colors';

.user_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-size: cover;
  margin-right: 20px;
  flex-shrink: 0;
  background-color: $light_grey;
}

.secondary_name {
  padding-top: 3px;
  color: $grey;
  font-size: 12px;
}

.add_icon,
.pending_icon {
  width: 30px;
}

.add_icon {
  fill: $blue;
}

.pending_icon {
  fill: $grey;
}

.added_icon {
  width: 28px;
  fill: $green;
}

.dots_button,
.icon_box {
  margin-left: auto;
}

.search_list {
  padding-bottom: 10px;
}

.right_align_box {
  text-align: right;
}

.underline_link {
  text-decoration: underline;
}

.name_box {
  padding-right: 5px;
}

.dots {
  width: 5px;
  fill: $blue;
}
