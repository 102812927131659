@import '../../../styles/colors.scss';
@import '../../../styles/breakpoints';

.card {
  box-sizing: border-box;
  height: 245px;
  margin: 0 auto;
  padding: 33px 20px;
  border-radius: 25px;
  box-shadow: 0px 12px 36px 0 rgba(47, 141, 227, 0.35);
  background-image: linear-gradient(251deg, #2fa9db, #2e6ded);
}

.card_row {
  padding-bottom: 24px;
}

.card_column_container {
  @extend .card_row;
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
}

.card_column {
  min-width: 80px;
}

.title {
  padding-bottom: 3px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.7px;
  text-align: left;
  color: #ffffff;
}

.card_input {
  box-sizing: border-box;
  width: 100%;
  padding: 5px 5px 2px;
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #c1daff;
  font-size: 16px;
  color: #c1daff;
  letter-spacing: 0.4px;
  font-family: 'SFUIDisplay-Semibold', Arial, Helvetica, sans-serif;
  background: transparent;
  opacity: 1;
  text-transform: uppercase;

  @include breakpoint(md_down) {
    font-family: 'SFUIDisplay-Regular', Arial, Helvetica, sans-serif;
  }

  &::placeholder {
    color: #c1daff;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: #c1daff;
    transition: background-color 9999s ease-in-out 9999s;
  }
}

.card_input_invalid {
  @extend .card_input;
  border-bottom: 1px solid $red;
}
